import { Timestamp } from 'firebase/firestore';

class EventLogger {
    constructor(firebaseManager) {
        this.firebaseManager = firebaseManager;
        this.gameId = null;  // Unique game ID for each session
    }

    // Set the unique game ID for each session
    setGameId(gameId) {
        this.gameId = gameId;
        this.firebaseManager.ensureGameDocumentExists(gameId);
    }

    // Method to log an event in Firebase
    logEvent(eventType, eventDetails = {}) {
        const isLocalhost = window.location.hostname === 'localhost';
        
        if (isLocalhost) {
            console.log('Running on localhost; event logging is disabled.');
            return;
        }
        
        if (!this.gameId) {
            console.error('Game ID not set. Cannot log event.');
            return;
        }
        const logEntry = {
            webTimestamp: Date.now(),  // Unix timestamp in milliseconds (web format)
            firebaseTimestamp: Timestamp.now(),  // Firebase native timestamp
            eventType,
            ...eventDetails
        };
    
        this.firebaseManager.saveLog(this.gameId, logEntry)
            .then(() => {
                console.log(`Event logged for game ${this.gameId}: ${eventType}`, logEntry);
            })
            .catch(err => {
                console.error(`Failed to log event for game ${this.gameId}: ${eventType}`, err);
            });
    }
}

export default EventLogger;