class CreatureAttackManager {
    constructor(onCreatureReadyToAttack, secondsPerSpeed = 10) {
        this.creatures = [];
        this.isPaused = false;
        this.tickInterval = null;
        this.onCreatureReadyToAttack = onCreatureReadyToAttack;
        this.secondsPerSpeed = secondsPerSpeed;
        this.pauseTime = null;
        this.playerSpeeds = {};
    }

    start() {
        if (!this.tickInterval) {
            this.tickInterval = setInterval(() => {
                this.tick();
            }, 50); // Process every 50ms
        }
        if (!this.playerSpeeds['player1']) this.playerSpeeds['player1'] = 1.0;
        if (!this.playerSpeeds['player2']) this.playerSpeeds['player2'] = 1.0;
    }

    pause() {
        if (!this.isPaused) {
            this.isPaused = true;
            this.pauseTime = Date.now();
            for (const creature of this.creatures) {
                creature.remainingTime = creature.nextAttackTime - this.pauseTime;
            }
        }
    }

    resume() {
        if (this.isPaused) {
            const resumeTime = Date.now();
            for (const creature of this.creatures) {
                creature.nextAttackTime = resumeTime + creature.remainingTime;
                delete creature.remainingTime;
            }
            this.isPaused = false;
            this.pauseTime = null;
        }
    }

    stop() {
        clearInterval(this.tickInterval);
        this.tickInterval = null;
        this.isPaused = false;
        this.pauseTime = null;
    }

    addCreature(creature) {
        for (const c of this.creatures) {
            if (c.creatureId === creature.creatureId) return;
        }
        const playerSpeed = this.playerSpeeds[creature.player] || 1.0;
        if (this.isPaused) {
            creature.remainingTime = (creature.speed * this.secondsPerSpeed * 1000) / playerSpeed;
        } else {
            creature.nextAttackTime = Date.now() + (creature.speed * this.secondsPerSpeed * 1000) / playerSpeed;
        }
        this.creatures.push(creature);
    }

    removeCreature(creatureId) {
        this.creatures = this.creatures.filter(c => c.creatureId !== creatureId);
    }

    setPlayerSpeedPercentage(player, percentage) {
        const oldPercentage = this.playerSpeeds[player] || 1.0;
        this.playerSpeeds[player] = percentage;

        const now = Date.now();
        for (const creature of this.creatures) {
            if (creature.player === player) {
                let remainingTime;
                if (this.isPaused) {
                    remainingTime = creature.remainingTime;
                } else {
                    remainingTime = creature.nextAttackTime - now;
                }
                const adjustedRemainingTime = remainingTime * (oldPercentage / percentage);
                if (this.isPaused) {
                    creature.remainingTime = adjustedRemainingTime;
                } else {
                    creature.nextAttackTime = now + adjustedRemainingTime;
                }
            }
        }
    }

    tick() {
        if (this.isPaused) return;

        const now = Date.now();
        for (const creature of this.creatures) {
            if (now >= creature.nextAttackTime) {

                console.log(now,Date.now());
                console.log('CreatureAttackManager: Creature ready to attack', creature);
                console.log(this.creatures);

                if (this.onCreatureReadyToAttack) {
                    const playerSpeed = this.playerSpeeds[creature.player] || 1.0;
                    creature.nextAttackTime = now + (creature.speed * this.secondsPerSpeed * 1000) / playerSpeed;
                    console.log('CreatureAttackManager: Attacking!', creature);
                    this.onCreatureReadyToAttack(creature);
                    
                }
                break; // Process only one creature per tick
            }
        }
    }
}

export default CreatureAttackManager;