import React, { useEffect, useState, useRef } from 'react';
import CoinBoxCalibrator from '../game/CoinBoxCalibrator';

const CoinBoxCalibratorComponent = ({ coinbox }) => {
  const [gameState, setGameState] = useState("");
  const isMounted = useRef(false);

  useEffect(() => {
    async function setup() {
      if (!coinbox) return;
      if (isMounted.current) return;
      isMounted.current = true;
      console.log('Calibrator:', coinbox);
      const newGameClient = new CoinBoxCalibrator(coinbox, setGameState);
      console.log('Game client:', newGameClient);
    }

    setup();
  }, [coinbox]);


  return (
    <div className="p-4 w-full">
      {(gameState === 'init') && (
        <div className="mb-4 p-4 w-full">
          <p>
          
          </p>
        </div>
      )}
    </div>
  );
};

export default CoinBoxCalibratorComponent;

