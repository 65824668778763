// FirebaseManager.js
import { firestore } from '../firebase/firebase';
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  onSnapshot,
  setDoc,
  serverTimestamp 
} from 'firebase/firestore';
import mitt from 'mitt';

class FirebaseManager {
  constructor(timeSyncService) {
    this.emitter = mitt();
    this.from = '';
    this.clientLatency = 0;
    this.timeDifference = 0;
    this.timeSyncService = timeSyncService;
  }

  async createGame() {
    const gameCollection = collection(firestore, "game");
    const gameName = `game-${Date.now()}`;
    const serverCurrentTime = this.timeSyncService.getServerTime();
    try {
      const newGameRef = await addDoc(gameCollection, {
        name: gameName,
        player1: "",
        player2: "",
        created: serverCurrentTime,
        state: 'waiting',
        eventsToServer: [],
        eventsToPlayer1: [],
        eventsToPlayer2: [],
        latency: [],
        stateChangeScheduled: serverCurrentTime,
        delay: 0,
        playersJoined: 0
      });
      this.from = 'server';
      this.measureAndReportLatency(newGameRef.id);
      this.listenToGameChanges(newGameRef.id);
      this.listenToGameEvents(newGameRef.id, this.from);
      console.log("Game created with ID:", newGameRef.id);
      return newGameRef.id;
    } catch (error) {
      console.error("Error creating game:", error);
    }
  }

  async joinGame(playerId) {
    const gameCollection = collection(firestore, "game");
    const gamesQuery = query(gameCollection, where("playersJoined", "<", 2), orderBy("created", "desc"), limit(1));
    const querySnapshot = await getDocs(gamesQuery);
    if (!querySnapshot.empty) {
      const gameDoc = querySnapshot.docs[0];
      const gameData = gameDoc.data();
      const updateData = {};
      let player = '';
      updateData.playersJoined = gameData.playersJoined + 1;

      if (!gameData.player1) {
        updateData.player1 = playerId;
        player = 'player1';
      } else if (!gameData.player2) {
        updateData.player2 = playerId;
        player = 'player2';
      }
      this.from = player;
      await updateDoc(doc(firestore, "game", gameDoc.id), updateData);
      this.measureAndReportLatency(gameDoc.id);
      this.listenToGameChanges(gameDoc.id);
      this.listenToGameEvents(gameDoc.id, this.from);
      console.log(`Player ${player} joined game with ID: ${gameDoc.id}`);
      return gameDoc.id;
    } else {
      console.log("No available games to join or all games are full.");
      return null;
    }
  }

  listenToGameChanges(gameId) {
    const gameDocRef = doc(firestore, "game", gameId);
    onSnapshot(gameDocRef, (docSnapshot) => {
      const gameData = docSnapshot.data();
      console.log(`${this.from} - Game data changed:`, gameData);
      if (gameData.stateChangeScheduled && gameData.delay > 0) {
        const serverTime = this.timeSyncService.getServerTime();
        const scheduledTime = gameData.stateChangeScheduled;
        const delay = gameData.delay;

        const timeUntilStateChange = scheduledTime + delay - serverTime;
        console.log(`${this.from} - State change scheduled in ${timeUntilStateChange}ms based on adjusted server time`);
        if (timeUntilStateChange > 0) {
          setTimeout(() => {
            this.emitter.emit("gameDataChanged", { ...gameData, check: timeUntilStateChange });
          }, timeUntilStateChange);
        } else {
          this.emitter.emit("gameDataChanged", gameData);
        }
      } else {
        this.emitter.emit("gameDataChanged", gameData);
      }
    });
  }


  listenToGameEvents(gameId, who) {
    let eventQueue = '';
    switch (who) {
      case 'server':
        eventQueue = 'eventsToServer';
        break;
      case 'player1':
        eventQueue = 'eventsToPlayer1';
        break;
      case 'player2':
        eventQueue = 'eventsToPlayer2';
        break;
      default:
        eventQueue = 'eventsToServer';
    }
    console.log(`${who} - Listening to game events in ${eventQueue}`);
    const eventsCollectionRef = collection(firestore, "game", gameId, eventQueue);
    onSnapshot(eventsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const eventData = change.doc.data();
          this.emitter.emit("gameEvent", eventData);
        }
      });
    });
  }

  async sendGameEvent(gameId, event) {
    const { to } = event;
    let eventQueue = ''
    if (to === 'all') {

    } else if (!to || to === '' || to === 'server') {
      eventQueue = 'eventsToServer';
    } else if (to === 'player1') {
      eventQueue = 'eventsToPlayer1';
    } else if (to === 'player2') {
      eventQueue = 'eventsToPlayer2';
    } else if (to === 'opponent') {
      eventQueue = this.from === 'player1' ? 'eventsToPlayer2' : 'eventsToPlayer1';
    }
    if (eventQueue === '') return;

    const eventCollection = collection(firestore, "game", gameId, eventQueue);
    try {
      await addDoc(eventCollection, {
        ...event,
        from: this.from
      });
    } catch (error) {
      console.error("Error sending game event:", error);
    }
  }

  async updateGameData(gameId, data, delay = 0) {
    try {
      const serverCurrentTime = this.timeSyncService.getServerTime();
      const gameDocRef = doc(firestore, "game", gameId);
      await updateDoc(gameDocRef, {
        ...data,
        delay: delay,
        stateChangeScheduled: serverCurrentTime
      });
    } catch (error) {
      console.error("Error updating game data:", error);
    }
  }

  emit(eventType, payload) {
    this.emitter.emit(eventType, payload);
  }

  on(eventType, handler) {
    this.emitter.on(eventType, handler);
  }

  off(eventType, handler) {
    this.emitter.off(eventType, handler);
  }


  // ---- latency

  async measureAndReportLatency(gameId) {
    // Assuming timeSyncService has already calculated these values
    const clientLatency = this.timeSyncService.latency;
    const timeDifference = this.timeSyncService.timeDiff;

    // Save the latency and time difference for the client
    const latencyRef = doc(collection(firestore, `game/${gameId}/latency`), this.from);
    try {
      await setDoc(latencyRef, { latency: clientLatency, timeDifference: timeDifference });
      //console.log(`${this.from} - Latency and time difference reported: ${clientLatency}ms, ${timeDifference}ms`);
    } catch (error) {
      console.error("Error reporting latency and time difference:", error);
    }
  }

  async updateGameDataSyncronized(gameId, data) {
    const latencyQuery = query(collection(firestore, `game/${gameId}/latency`));
    try {
      const querySnapshot = await getDocs(latencyQuery);
      let maxLatency = 0;
      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.latency > maxLatency) {
          maxLatency = data.latency;
        }
      });

      const totalDelay = maxLatency + 500; // firebase write latency
      await this.updateGameData(gameId, data, totalDelay);

      //console.log(this.from,"- State change scheduled with max latency consideration.");
    } catch (error) {
      console.error("Error calculating max latency/scheduling state change:", error);
    }
  }


  async getCards() {
    const cardsCollection = collection(firestore, 'cards');
    try {
      const cardsSnapshot = await getDocs(cardsCollection);
      const cards = cardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return cards;
    } catch (error) {
      console.error('Error fetching cards:', error);
      return [];
    }
  }

  async addCard(card) {
    const cardCollection = collection(firestore, 'cards');
    try {
      console.log('Adding card to Firestore:', card); // Debugging log
      await addDoc(cardCollection, card);
      console.log('Card added successfully');
    } catch (error) {
      console.error('Error adding card:', error);
    }
  }

  async updateCard(cardId, card) {
    const cardDoc = doc(firestore, 'cards', cardId);
    try {
      console.log('Updating card in Firestore:', card); // Debugging log
      await updateDoc(cardDoc, card);
      console.log('Card updated successfully');
    } catch (error) {
      console.error('Error updating card:', error);
    }
  }

  async addDeck(deck) {
    const deckCollection = collection(firestore, 'decks');
    try {
      console.log('Adding deck to Firestore:', deck); // Debugging log
      await addDoc(deckCollection, deck);
      console.log('Deck added successfully');
    } catch (error) {
      console.error('Error adding deck:', error);
    }
  }

  async updateDeck(deckId, deck) {
    const deckDoc = doc(firestore, 'decks', deckId);
    try {
      console.log('Updating deck in Firestore:', deck); // Debugging log
      await updateDoc(deckDoc, deck);
      console.log('Deck updated successfully');
    } catch (error) {
      console.error('Error updating deck:', error);
    }
  }

  async getDeck(deckId) {
    const deckDoc = doc(firestore, 'decks', deckId);
    try {
      const deckSnapshot = await getDoc(deckDoc);
      if (deckSnapshot.exists()) {
        return { id: deckSnapshot.id, ...deckSnapshot.data() };
      } else {
        console.error('No such deck!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching deck:', error);
      return null;
    }
  }

  async getDecks() {
    const decksCollection = collection(firestore, 'decks');
    try {
      const decksSnapshot = await getDocs(decksCollection);
      const decks = decksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return decks;
    } catch (error) {
      console.error('Error fetching decks:', error);
      return [];
    }
  }

  async getAIs() {
    const aiCollection = collection(firestore, 'AIs');
    const aiSnapshot = await getDocs(aiCollection);
    return aiSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  async addPlayerConfiguration(config) {
    const configCollection = collection(firestore, 'playerconf');
    return await addDoc(configCollection, config);
  }

  async updatePlayerConfiguration(id, config) {
    const configDoc = doc(firestore, 'playerconf', id);
    return await updateDoc(configDoc, config);
  }

  async getPlayerConfigurations() {
    const configCollection = collection(firestore, 'playerconf');
    const configSnapshot = await getDocs(configCollection);
    return configSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  async getPlayerConfiguration(configId) {
    const configRef = doc(firestore, "playerconf", configId);
    const configDoc = await getDoc(configRef);
    if (configDoc.exists()) {
      return { id: configDoc.id, ...configDoc.data() };
    } else {
      console.error("No such configuration found!");
      return null;
    }
  }

  async ensureGameDocumentExists(gameId) {
    try {
        const gameDocRef = doc(firestore, 'replays', gameId);
        await setDoc(gameDocRef, {
            firebaseTimestamp: serverTimestamp()  // Add the timestamp field
        }, { merge: true }); // Merge ensures no overwrites
        console.log(`Game document ensured for game ${gameId}`);
    } catch (error) {
        console.error("Error ensuring game document:", error);
    }
}

  async saveLog(gameId, logEntry) {
    try {
      const eventCollection = collection(firestore, 'replays', gameId, 'events');
      await addDoc(eventCollection, logEntry);
      console.log(`Log saved for game ${gameId}`);
    } catch (error) {
      console.error("Error saving log:", error);
    }
  }

}

export default FirebaseManager;
