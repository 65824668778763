class EventManager {
    constructor(onEventTriggered, eventSchedule = []) {
        this.events = []; // Array of events with scheduled times
        this.tickInterval = null;
        this.isPaused = false;
        this.pauseTime = null;
        this.onEventTriggered = onEventTriggered;
        this.eventSchedule = eventSchedule; // Array of events with relative times
    }

    start() {
        const now = Date.now();
        // Initialize events with absolute times
        for (const event of this.eventSchedule) {
            const scheduledTime = now + event.time;
            this.events.push({ ...event, scheduledTime });
        }
        // Start the tick
        if (!this.tickInterval) {
            this.tickInterval = setInterval(() => {
                this.tick();
            }, 50); // Process every 50ms
        }
    }

    pause() {
        if (!this.isPaused) {
            this.isPaused = true;
            this.pauseTime = Date.now();
            // Store remaining time for each event
            for (const event of this.events) {
                event.remainingTime = event.scheduledTime - this.pauseTime;
            }
        }
    }

    resume() {
        if (this.isPaused) {
            const now = Date.now();
            // Adjust scheduledTime for all events
            for (const event of this.events) {
                event.scheduledTime = now + event.remainingTime;
                delete event.remainingTime;
            }
            this.isPaused = false;
            this.pauseTime = null;
        }
    }

    stop() {
        if (this.tickInterval) {
            clearInterval(this.tickInterval);
            this.tickInterval = null;
        }
        this.events = [];
        this.isPaused = false;
        this.pauseTime = null;
    }

    tick() {
        if (this.isPaused) return;

        const now = Date.now();

        // Sort events by scheduledTime
        this.events.sort((a, b) => a.scheduledTime - b.scheduledTime);

        for (let i = 0; i < this.events.length; i++) {
            const event = this.events[i];
            if (now >= event.scheduledTime) {
                // Trigger the event
                let eventHappend = false;
                if (this.onEventTriggered) {
                    eventHappend = this.onEventTriggered(event);
                }
                // Remove the event from the array
                if(eventHappend) {
                    this.events.splice(i, 1);
                }
                break; // Process only one event per tick
            }
        }
    }
}

export default EventManager;