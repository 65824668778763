import React, { useState } from 'react';
import BluetoothDeviceManager from '../../game/BluetoothDeviceManager';
import CoinBoxBluetooth from '../../game/CoinBoxBluetooth';
import AudioPlayer from '../../game/AudioPlayer';
import NoSleep from 'nosleep.js';

const BluetoothControlSoccer = ({ setCoinbox }) => {
  // Corrected useState usage to include both state variable and its setter
  const [bluetoothManager, setBluetoothManager] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState('');
  const [coinboxInitialized, setCoinboxInitialized] = useState(false);

  const handleInitializeClick = async () => {
    try {
      const audioPlayer = new AudioPlayer(["playing-coins", "background", "helper", "notification", "opponent", "spellcast"]);
      audioPlayer.startSilentStreamsForAllTracks();
      const noSleep = new NoSleep();
      noSleep.enable();
      audioPlayer.play("sound", '/audio/bluetooth.mp3', 1, "notification");
      const manager = new BluetoothDeviceManager(false,2); // not calibrate mode - game 2 = soccer
      await manager.connectAndGetCharacteristic();
      setBluetoothManager(manager);
      const newCoinbox = new CoinBoxBluetooth(manager, audioPlayer);
      await newCoinbox.waitForSetupCompletion();
      newCoinbox.setId(manager.deviceId);
      await newCoinbox.start();
      setCoinbox(newCoinbox);
      setIsConnected(true);
      setCoinboxInitialized(true);
    } catch (err) {
      setError('Failed to initialize Bluetooth Device Manager.');
      console.error(err);
    }
  };
  return (
    <div className="p-4">
      {error && <p className="text-red-500">{error}</p>}
      {!isConnected && (
        <button
          onClick={handleInitializeClick}
          className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Play Football
        </button>
      )}
      {bluetoothManager && !coinboxInitialized && <p className="text-indigo-500">Creation Started</p>}
    </div>
  );
};

export default BluetoothControlSoccer;
