import React, { useEffect, useState, useRef } from 'react';
import CoinBoxSoccer from '../game/CoinBoxSoccer';

const CoinBoxSoccerComponent = ({ coinbox }) => {
    const [gameState, setGameState] = useState("");
    const [cardState, setCardState] = useState([]);
    const isMounted = useRef(false);

    useEffect(() => {
        async function setup() {
            if (!coinbox) return;
            if (isMounted.current) return;
            isMounted.current = true;
            console.log('Soccer:', coinbox);
            const newGameClient = new CoinBoxSoccer(coinbox, setGameState, setCardState);
            console.log('Game client:', newGameClient);
        }

        setup();
    }, [coinbox]);


    return (
        <div className="p-4 w-full">
            {(gameState === 'setupGame') && (
                <div className="mb-4 p-4 w-full">
                    <h2 className="text-xl font-bold">Detecing Football Arena<br />Please remove all cards and pieces!</h2>
                    <p>
                        Red Formation: {(cardState.redSpell !== 1) ? 'Not detected!' : 'Ok!'}<br />
                        Red Action: {(cardState.redLife !== 1) ? 'Not detected!' : 'Ok!'}<br />
                        Blue Formation: {(cardState.blueSpell !== 1) ? 'Not detected!' : 'Ok!'}<br />
                        Blue Action: {(cardState.blueLife !== 1) ? 'Not detected!' : 'Ok!'}
                    </p>
                </div>
            )}
            {(gameState === 'boardReady') && (
                <div className="mb-4 p-4 w-full">
                    <div className="mb-4 p-4 w-full">
                        <div className="mb-4 p-4 w-full" style={{
                            background: 'red',
                            color: 'white',
                            transform: 'rotate(180deg)',
                        }}>
                            <h2 className="text-xl font-bold">Lets get ready!</h2>
                            <p>
                                Welcome to Football Kings! <br />
                                Please place your formation cards on the board.  <br />
                                Now the starting player - places the ball card in the action square.
                            </p>
                            Formation: {cardState.player1formation}<br />
                        </div>
                        <div className="mb-4 p-4 w-full" style={{
                            background: 'blue',
                            color: 'white',
                            transform: '',
                        }}>                    <h2 className="text-xl font-bold">Lets get ready!</h2>
                            <p>
                                Welcome to Football Kings! <br />
                                Please place your formation cards on the board.  <br />
                                Now the starting player - places the ball card in the action square.
                            </p>
                            Formation: {cardState.player2formation}<br />
                        </div>
                    </div>
                </div>
            )}
            {(gameState === 'gameStartCountDown') && (
                <div className="mb-4 p-4 w-full">
                    <h2 className="text-xl font-bold">{cardState.count}</h2>
                </div>
            )}
            {(gameState === 'gameStart') && (
                <div className="mb-4 p-4 w-full">
                    <h2 className="text-xl font-bold">Starting!</h2>
                </div>
            )}
            {(gameState === 'realTime') && (
                <div className="mb-4 p-4 w-full">
                    <div className="mb-4 p-4 w-full" style={{
                        background: 'red',
                        color: 'white',
                        transform: 'rotate(180deg)',
                    }}>
                        Formation: {cardState.player1formation}<br />
                    </div>
                    <div className="mb-4 p-4 w-full" style={{
                        background: 'blue',
                        color: 'white',
                        transform: '',
                    }}>
                        Formation: {cardState.player2formation}<br />
                    </div>
                </div>
            )}
            {(gameState === 'waitForEndEvent') && (
                <div className="mb-4 p-4 w-full">
                <div className="mb-4 p-4 w-full" style={{
                    background: 'red',
                    color: 'white',
                    transform: 'rotate(180deg)',
                }}>
                    <h1>Move all your players</h1>
                    Status: {cardState.player1completed?'Done!':'In progress'}<br />
                </div>
                <div className="mb-4 p-4 w-full" style={{
                    background: 'blue',
                    color: 'white',
                    transform: '',
                }}>
                    <h1>Move all your players</h1>
                    Status: {cardState.player2completed?'Done!':'In progress'}<br />
                </div>
            </div>
            )}
        </div>
    );
};

export default CoinBoxSoccerComponent;

