import React, { useState } from 'react';
import BluetoothControl from '../BluetoothControl/BluetoothControl';
import BluetoothControlCalibration from '../BluetoothControl/BluetoothControlCalibration';
import BluetoothControlSoccer from '../BluetoothControl/BluetoothControlSoccer';
import BluetoothControlRTS from '../BluetoothControl/BluetoothControlRTS';
import Wizards3ClientComponent from './Wizards3ClientComponent';
import CoinBoxCalibratorComponent from '../CoinBoxCalibratorComponent';
import CoinBoxSoccerComponent from '../CoinBoxSoccerComponent';
import CoinBoxRTSComponent from '../CoinBoxRTSComponent.js';
// import ReplayViewer from './ReplayViewer';
import { useAuth } from '../../contexts/AuthContext';

const Wizard3Main = () => {
  const { currentUser } = useAuth();
  const [CoinBox, setCoinbox] = useState(null);
  const [CoinBoxCalibrator, setCoinboxCalibrator] = useState(null);
  const [CoinBoxSoccer, setCoinBoxSoccer] = useState(null);
  const [CoinBoxRTS, setCoinBoxRTS] = useState(null);
  return (
    <div className="min-h-screen bg-gray-50 p-4 w-full">
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS &&
        <div>
          <h1 className="text-xl font-bold mb-4">{currentUser.email}</h1>
        </div>
      }
      {!CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && <BluetoothControl setCoinbox={setCoinbox} />}
      {!CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && CoinBox && <Wizards3ClientComponent coinbox={CoinBox} />}

      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && <BluetoothControlRTS setCoinbox={setCoinBoxRTS} />}
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && CoinBoxRTS && <CoinBoxRTSComponent coinbox={CoinBoxRTS} />}

      {!CoinBox && !CoinBoxCalibrator && !CoinBoxRTS && <BluetoothControlSoccer setCoinbox={setCoinBoxSoccer} />}
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxRTS && CoinBoxSoccer && <CoinBoxSoccerComponent coinbox={CoinBoxSoccer} />}

      {!CoinBox && !CoinBoxSoccer && !CoinBoxRTS && <BluetoothControlCalibration setCoinbox={setCoinboxCalibrator} />}
      {!CoinBox && !CoinBoxSoccer && !CoinBoxRTS && CoinBoxCalibrator && <CoinBoxCalibratorComponent coinbox={CoinBoxCalibrator} />}

      <div>v1.6</div>
    </div>
  );
};
export default Wizard3Main;