import ServerlessClientBase from './ServerlessClientBase';
import EventManager from './wizards3/EventManager';

class CoinBoxSoccer extends ServerlessClientBase {
    constructor(coinbox, onGameStateChange = null, onCardStateChange = null) {
        super(coinbox);

        this.onGameStateChange = onGameStateChange;
        this.onCardStateChange = onCardStateChange;

        // Game State
        this.gameIsActive = false;
        this.currentState = 'setupGame';
        this.currentPlayer = 'box';
        this.turnBasedActive = false;
        this.ballPossession = 'player1';
        this.waitForEventToEnd = false;

        this.backgroundAudio = {
            'player1': 'background',
            'player2': 'opponent',
            'box': 'notification'
        }

        // Game Data
        this.currentCard = null;
        this.playerFormations = {
            'player1': null,
            'player2': null
        };

        this.eventSchedule = [
            { time: 10000, eventId: 1 },
        ];
        this.eventManager = new EventManager(this.handleGameEvent.bind(this), this.eventSchedule);

        this.cards =
        {
            'card11': { 'name': 'Ball possession', 'effect': 'possession' },
            'card12': { 'name': 'RED: Take action!', 'effect': 'turnbased' },
            'card13': { 'name': 'BLUE: Take action!', 'effect': 'turnbased' },
            'card14': { 'name': 'Goal attempt', 'effect': 'turnbased,goalattempt' },
            'card20': { 'name': '4-4-2 (default)', 'effect': 'formation', 'attack': { 'red': 10000, 'green': 10000, 'blue': 10000, 'purple': 5000, 'yellow': 9999000 }, 'defence': { 'red': 10000, 'green': 10000, 'blue': 10000, 'purple': 10000, 'yellow': 9999000 } },
            'card21': { 'name': '5-3-2 (defensive)', 'effect': 'formation', 'attack': { 'red': 12000, 'green': 10000, 'blue': 8000, 'purple': 5000, 'yellow': 9999000 }, 'defence': { 'red': 12000, 'green': 8000, 'blue': 12000, 'purple': 10000, 'yellow': 9999000 } },
            'card22': { 'name': '4-3-3 (attacking)', 'effect': 'formation', 'attack': { 'red': 8000, 'green': 12000, 'blue': 10000, 'purple': 12000, 'yellow': 30000 }, 'defence': { 'red': 8000, 'green': 12000, 'blue': 10000, 'purple': 12000, 'yellow': 30000 } },
        }

        this.coinGeneration =
        {
            'player1red': { 'firstdelay': 10000, 'delay': 60000, 'colors': ['red', 'red', 'red',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray1' },
            'player2red': { 'firstdelay': 10000, 'delay': 60000, 'colors': ['red', 'red', 'red',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray2' },
            'player1green': { 'firstdelay': 10000, 'delay': 45000, 'colors': ['green', 'green', 'green',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray1' },
            'player2green': { 'firstdelay': 10000, 'delay': 45000, 'colors': ['green', 'green', 'green',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray2' },
            'player1blue': { 'firstdelay': 10000, 'delay': 45000, 'colors': ['blue', 'blue', 'blue',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray1' },
            'player2blue': { 'firstdelay': 10000, 'delay': 45000, 'colors': ['blue', 'blue', 'blue',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray2' },
            'player1purple': { 'firstdelay': 10000, 'delay': 30000, 'colors': ['purple', 'purple', 'purple',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray1' },
            'player2purple': { 'firstdelay': 10000, 'delay': 30000, 'colors': ['purple', 'purple', 'purple',], 'firstNumberCoins': 1, 'index': 0, 'tray': 'tray2' },
            'player1yellow': { 'firstdelay': 0, 'delay': 999000, 'colors': ['yellow', 'yellow', 'yellow',], 'firstNumberCoins': 3, 'index': 0, 'tray': 'tray1' },
            'player2yellow': { 'firstdelay': 0, 'delay': 999000, 'colors': ['yellow', 'yellow', 'yellow',], 'firstNumberCoins': 3, 'index': 0, 'tray': 'tray2' },
        }

        this.transitionToState('setupGame');
    }

    initSoccer() {
        //this.coinGeneratorStart();
    }

    // ------------------------------

    transitionToState(newState) {
        console.log(`Transitioning to state: ${newState}`);
        this.currentState = newState;
        if (this.onGameStateChange) this.onGameStateChange(this.currentState);

        switch (newState) {
            case 'setupGame':
                this.setupGame();
                break;
            case 'boardReady':
                this.prepareBoard();
                break;
            case 'gameStartCountDown':
                this.startGameCountDown();
                break;
            case 'gameStart':
                this.startGame();
                break;
            case 'realTime':
                this.enterRealTime();
                break;
            case 'turnBased':
                this.enterTurnBasedMode();
                break;
            case 'waitForEndEvent':
                this.waitForEndEvent();
                break;
            case 'overtime':
                this.handleOverTime();
                break;
            case 'resolveCard':
                this.resolveCurrentCard();
                break;
            case 'resumeCountDown':
                this.startResumeCountDown();
                break;
            case 'gameOver':
                this.endGame();
                break;
            default:
                console.warn(`Unknown state: ${newState}`);
        }
    }

    // ------------------------------

    setupGame() {
        this.coinbox.on('vision', this.handleVisionEvent);
        this.coinbox.on('inputcoin', this.handleCoinDetected);
        this.coinbox.on('input', this.handleCoinColorDetected);
        this.coinbox.on('output', this.handleCoinOutput);
        this.coinbox.setInputTray('tray1');
        this.coinGeneratorSetup();
    }

    async prepareBoard() {
        this.coinbox.setColorMode(3);
        await this.start();
        await this.coinbox.pauseOutput();
        this.coinbox.audioPlayer.queueOrPlay('gamereadytostart', '/audio/football/football_startinstructions.mp3', 8, 'notification');
    }

    startGameCountDown() {
        this.coinbox.audioPlayer.queueOrPlay('game_start', '/audio/football/game_start.mp3', 10, 'background');
        this.counter = 3;
        this.countDownTimer = setInterval(() => {
            if (this.counter === 0) {
                clearInterval(this.countDownTimer);
                this.transitionToState('gameStart');
                this.coinbox.audioPlayer.play(
                    `whisle`,
                    `/audio/football/whisle.mp3`,
                    5,
                    'notification'
                );
            } else {
                this.coinbox.audioPlayer.play(
                    `number_${this.counter}`,
                    `/audio/wizards/number_${this.counter}.mp3`,
                    5,
                    'notification'
                );
                this.updateCardState({ 'count': this.counter });
                this.counter--;
            }
        }, 1000);
    }

    async startGame() {
        await this.pauseAndClear();
        await this.start();
        this.coinGeneratorStart();
        await this.pause();
        this.gameIsActive = true;
        this.eventManager.start();
        this.transitionToState('realTime');
        this.gameStartTime = Date.now();
    }

    enterRealTime() {
        if (this.turnbasedTimeStart) {
            this.turnbasedTime += Date.now() - this.turnbasedTimeStart;
            this.turnbasedTimeStart = null;
        }
        this.resumeGame();
    }

    enterTurnBasedMode() {
        this.turnbasedTimeStart = Date.now();
        this.pauseGame();
        this.transitionToState('resolveCard');
    }

    startResumeCountDown() {

        if (this.changeCardsInterval) {   // super weird that its here
            clearInterval(this.changeCardsInterval);
            this.changeCardsInterval = null;
        }

        this.counter = 0;

        this.countDownTimer = setInterval(() => {
            if (this.counter === 0) {
                clearInterval(this.countDownTimer);
                this.currentCard = null;
                //this.coinbox.audioPlayer.play(`whisle`, `/audio/football/whisle.mp3`, 5, 'notification');
                this.coinbox.audioPlayer.queueOrPlay('go', '/audio/wizards2/go.mp3', 5, 'notification');
                this.transitionToState('realTime');
            } else {
                this.updateCardState({ 'count': this.counter });
                this.coinbox.audioPlayer.queueOrPlay(
                    `number_${this.counter}`,
                    `/audio/wizards/number_${this.counter}.mp3`,
                    5,
                    'notification'
                );
                this.counter--;
            }
        }, 1000);
    }

    endGame() {
        this.pauseGame();
        this.eventManager.stop();

        let won = 'player1';
        let lost = 'player2';
        if (this.playerStates['player1'].life === 0) {
            won = 'player2';
            lost = 'player1';
        }
        const data = {
            'won': won, 'lost': lost,
            'player1manaplayed': this.player1ManaPlayed, 'player2manaplayed': this.player2ManaPlayed,
            'player1creaturesplayed': this.player1CreaturesPlayed, 'player2creaturesplayed': this.player2CreaturesPlayed,
            'gameDurationMin': Math.floor((Date.now() - this.gameStartTime) / 60000),
            'gameDurationSec': Math.floor((Date.now() - this.gameStartTime) / 1000) % 60,
            'turnBasedTimeMin': Math.floor(this.turnbasedTime / 60000),
            'turnBasedTimeSec': Math.floor(this.turnbasedTime / 1000) % 60
        };
        this.coinbox.audioPlayer.queueOrPlay('game_won', '/audio/wizards/game_won.mp3', 2, 'notification');
        this.updateCardState(data);
        //this.eventLogger.logEvent('game_end', data);
    }

    // Pause and Resume Game Methods
    async pauseGame() {
        this.turnBasedActive = true;
        await this.coinbox.pauseOutput();
        this.pauseAllEvents();
        this.eventManager.pause();
        this.coinbox.setColorMode(1);
        //this.eventLogger.logEvent('game_realtime_ended');
    }

    updateRealTimeScores() {
        if (this.turnBasedActive) return;
        const data = {
            'ballpossession': this.ballPossession,
            'player1formation': this.playerFormations['player1'] ? this.playerFormations['player1'].name : '',
            'player2formation': this.playerFormations['player2'] ? this.playerFormations['player2'].name : '',
        };
        this.updateCardState(data);
    }

    async resumeGame() {

        this.turnBasedActive = false;
        //this.calculateManaBoost();
        //this.calculateAttackBoost();

        await this.start();
        this.resumeAllEvents();
        this.eventManager.resume();
        this.coinbox.setColorMode(3);
        this.updateRealTimeScores();
        //this.eventLogger.logEvent('game_realtime_started');
    }

    resolveCurrentCard() {
        if (this.currentCard) {
            this.currentPlayer = this.currentCard.player;
            this.updateColorMode();
            // wait for card to be removed           
        } else {
            this.transitionToState('resumeCountDown');
        }
    }

    waitForEndEvent() {
        // her we wait for both players to remove their action acrds from the action area.
        this.waitForEventToEnd = true;
        this.removedCards = { 'player1': false, 'player2': false };
        const data = {
            'player1completed': false,
            'player2completed': false,
        };
        this.updateCardState(data);
    }


    // ------------------------------

    async updateColorMode() {
        if (this.currentPlayer === 'box') {
            this.coinbox.setColorMode(3);
        } else {
            const mode = this.currentPlayer === 'player1' ? 2 : 0;
            this.coinbox.setColorMode(mode);
            const tray = 'tray' + (this.currentPlayer === 'player1' ? '1' : '2');
            this.coinbox.setInputTray(tray);
        }
        console.log('updateColorMode - PAUSEOUTPUT:', this.currentPlayer);
    }

    updateCardState(card) {
        if (this.onCardStateChange) {
            this.onCardStateChange({ ...card });
        }
    }


    getCardEffect(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.effect : '';
    }

    getCardShortName(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.name.toLowerCase().replace(' ', '_') : '';
    }

    getCardName(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.name : '';
    }

    getCardType(cardTypeId) {
        const cardTypeMapping = {
            1: 'formation',
            2: 'action',
        };
        return cardTypeMapping[cardTypeId] || 'unknown';
    }

    updateCoinGeneratorDelays(player) {
        const formation = this.playerFormations[player];
        if (!formation) {
            console.warn(`No formation set for ${player}. Cannot update coin generator delays.`);
            return;
        }

        // Determine whether to use attack or defence based on ball possession
        const useAttack = this.ballPossession === player;

        const statType = useAttack ? 'attack' : 'defence';
        const stats = formation[statType];

        if (!stats) {
            console.warn(`No ${statType} stats found in formation for ${player}.`);
            return;
        }

        // Iterate over the player's coinGenerators and update their delays
        for (const [generatorId, generatorData] of Object.entries(this.coinGeneration)) {
            if (generatorId.startsWith(player)) {
                const color = generatorData.colors[0]; // All colors in the array are the same
                const statValue = stats[color];

                if (statValue === undefined) {
                    console.warn(`No stat value found for color ${color} in ${statType} stats.`);
                    continue;
                }

                generatorData.delay = statValue;

                console.log(`Updated delay for ${generatorId}: ${generatorData.delay} ms`);
            }
        }
    }

    // ------------------------------

    handleCardPlayed(player, cardTypeId, cardId) {
        console.log('handleCardPlayed:', player, cardTypeId, cardId, this.currentState);
        if (!this.gameIsActive && this.currentState !== 'boardReady') return;

        const cardType = this.getCardType(cardTypeId);

        /*
        this.eventLogger.logEvent('card_played', {
            player,
            cardId,
            cardType,
        });
        */

        if (cardType === 'unknown') return;
        if (cardType === 'formation' && cardId < 20) return;
        if (cardType === 'action' && cardId >= 20) return;

        if (this.waitForEventToEnd) return;

        const playerStr = `player${player}`;
        const channel = this.backgroundAudio[playerStr];
        const card = {
            player: playerStr,
            cardType,
            cardId,
            name: this.getCardName(cardId),
            shortName: this.getCardShortName(cardId),
            effect: this.getCardEffect(cardId),
        };

        if (cardType === 'formation') {
            if (this.playerFormations[playerStr] === this.cards[`card${cardId}`]) return;
            const soundname = playerStr + '_formation_updated';
            this.coinbox.audioPlayer.queueOrPlay(soundname, '/audio/football/' + soundname + '.mp3', 10, channel);

            // Store the player's current formation
            this.playerFormations[playerStr] = this.cards[`card${cardId}`];

            // Update the coin generator delays based on the new formation
            this.updateCoinGeneratorDelays(playerStr);

            this.updateRealTimeScores();

            return;
        }

        if (cardId === 11) {
            if (this.ballPossession === playerStr) return;
            const possession = playerStr + '_possession';
            this.coinbox.audioPlayer.play(possession, '/audio/football/' + possession + '.mp3', 10, channel);
            this.ballPossession = playerStr;
            this.updateCoinGeneratorDelays('player1');
            this.updateCoinGeneratorDelays('player2');
            this.updateRealTimeScores();
            return;
        }

        if (!this.gameIsActive) return; // the previous part of the code is for setting up the game.
        //this.coinbox.audioPlayer.play(`whisle`, `/audio/football/whisle.mp3`, 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay(playerStr, '/audio/football/' + playerStr + '.mp3', 10, "spellcast");
        this.currentCard = card;
        this.transitionToState('turnBased');

    }

    handleCardRemoved(player, cardTypeId, cardId) {
        if (!this.gameIsActive && !this.waitForEventToEnd) return;

        const cardType = this.getCardType(cardTypeId);

        /*
        this.eventLogger.logEvent('card_played', {
            player,
            cardId,
            cardType,
        });
        */

        if (cardType === 'unknown') return;
        if (cardType === 'formation' && cardId < 20) return;
        if (cardType === 'action' && cardId >= 20) return;
        if (cardType === 'action' && cardId === 11) return; // dont care if you remove the ball card

        if (this.waitForEventToEnd) {
            this.removedCards[`player${player}`] = true;
            const data = {
                'player1completed': this.removedCards['player1'],
                'player2completed': this.removedCards['player2'],
            };
            this.updateCardState(data);
            if (this.removedCards.player1 && this.removedCards.player2) {
                this.waitForEventToEnd = false;
                this.transitionToState('resumeCountDown');
            }
            return;
        }

        if (cardType === 'formation') {
            // no formation ?

            //const soundname = playerStr + '_formation_updated';
            //this.coinbox.audioPlayer.queueOrPlay('soundname', '/audio/football/'+soundname+'.mp3', 10, channel);
        }

        if (cardType === 'action') {
            this.currentCard = null;
            this.transitionToState('resumeCountDown');
        }

    }

    // ------------------------------


    handleGameEvent(event) {
        if (this.currentState !== 'realTime') return false;
        this.turnbasedTimeStart = Date.now();
        this.currentPlayer = 'box';
        this.pauseGame();
        if (event.eventId === 1) {
            // play some kind of sound
            this.coinbox.audioPlayer.queueOrPlay(`whisle`, `/audio/football/whisle.mp3`, 10, 'spellcast');
            this.coinbox.audioPlayer.queueOrPlay(
                'event_moveplayers',
                '/audio/football/event_moveplayers.mp3',
                10,
                'spellcast'
            );

            // the player would need to play and remove their action card - lets say we just listen to remove action acard event
            this.transitionToState('waitForEndEvent');
        }
        return true;
    }

    // ------------------------------

    handleVisionEvent = (event) => {
        const { eventId, player, cardtype, cardId } = event;
        /* this.eventLogger.logEvent('vision_event', {
             eventId,
             player,
             cardtype,
             cardId,
         });*/
        switch (eventId) {
            case 1:
                if (this.currentState === 'setupGame') this.transitionToState('boardReady');
                break;
            case 2:
                this.handleCardPlayed(player, cardtype, cardId);
                break;
            case 3:
                this.handleCardRemoved(player, cardtype, cardId);
                break;
            case 4:
                if (this.currentState === 'boardReady') this.transitionToState('gameStartCountDown');
                break;
            case 5:
                this.handleSetupDetectionsFailed(event);
                // we will handle this later
                break;
            case 6:
                console.log('Hand movement detected');
                break;
            case 7:
                console.log('Hand movement stopped');
                break;

            default:
                console.log('Unknown vision event ID');
        }
    };

    handleSetupDetectionsFailed = (event) => {
        this.coinbox.audioPlayer.play('removeallcards', '/audio/football/football_removeall.mp3', 10, 'notification');
        console.log(event);
        this.updateCardState(event);;
    };

    // ------------------------------

    handleCoinDetected = () => {
        console.log('Coin detected');
        this.coinbox.audioPlayer.play('coin_detected', '/audio/wizards/pling.mp3', 10, 'notification');
    };

    handleCoinColorDetected = (event) => {
        const { action, colorname } = event;
        if (action !== 'coin') return;
        console.log('Coin color detected: ', colorname);
        const color = 'color_' + colorname;
        this.coinbox.audioPlayer.play(color, '/audio/wizards/' + color + '.mp3', 10, 'helper');
        //this.coinbox.useInputQueue();
    };

    handleCoinOutput = (event) => {
        const { outputTray, colorname } = event;
        console.log('Coin output: ', colorname, outputTray);
    };

    // ------------------------------

    coinGeneratorSetup = () => {
        this.coinbox.on('input', this.coinGeneratorRestart);   // this is the magic line - it makes sure that every time a coin is inserted alle coin genereators are restarted if needed
        for (const [id, data] of Object.entries(this.coinGeneration)) {
            data.colors = this.coinbox.random.shuffle([...data.colors]);
            this.coinbox.on(id, this.coinGenerator);
        }
    }

    coinGeneratorStart = () => {
        for (const [id] of Object.entries(this.coinGeneration)) {
            this.coinGeneratorAddCoin(id);
        }
    }

    coinGeneratorAtMaxCapacity = (color, maxCoins, tray) => {
        const coinsInPlay = this.coinbox.coinsInPlay(color, tray);
        return coinsInPlay >= maxCoins;
    }

    coinGeneratorIsRunning = (id) => {
        return this.coinbox.getAllFromOutputQueue().some(coin => coin.callbackName === id)
    }

    coinGeneratorInstantOutput = (id) => {
        const coin = this.coinbox.getAllFromOutputQueue().find(coin => coin.callbackName === id);
        const now = Date.now();
        if (!coin || coin.timestamp <= now) return false;
        coin.timestamp = now;
        return true;
    }

    coinGeneratorAddCoin = (id) => {
        console.log('coinGeneratorAddCoin:', id);
        if (this.coinGeneratorIsRunning(id)) return false;
        const data = this.coinGeneration[id];
        const numberOfCoinsInPool = data.colors.length;
        console.log('coinGeneratorAddCoin - length:', numberOfCoinsInPool);
        let maxTries = numberOfCoinsInPool;
        data.index = Math.floor(Math.random() * numberOfCoinsInPool); // start at random position
        while (maxTries > 0) {
            maxTries -= 1;
            const index = data.index;
            data.index = (index + 1) % numberOfCoinsInPool;
            const colorName = data.colors[index];
            const color = this.colorNames.indexOf(colorName);
            const capacity = data.colors.filter(c => c === colorName).length;
            if (this.coinGeneratorAtMaxCapacity(color, capacity, data.tray)) continue;
            let timeout = Date.now() + (data.firstNumberCoins > 0 ? data.firstdelay : data.delay);
            data.firstNumberCoins -= 1;
            if (timeout < Date.now()) timeout = Date.now() + 500;
            this.coinbox.addToOutputQueue(color, timeout, id, data.tray);
            console.log('coinGeneratorAddCoin - addToOutputQueue:', colorName, timeout, id, data.tray);
            return true;
        }
        return false;
    }

    coinGenerator = (event) => {
        this.coinGeneratorAddCoin(event.callbackName);
    }

    coinGeneratorRestart = (event) => {
        if (event.action === 'cancel') {
            console.log('cancel coin');
        } else if (event.action !== 'used') return;
        this.coinGeneratorStart();
    }

}

export default CoinBoxSoccer;